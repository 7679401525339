import MultiSelect from "../../../../components/MultiSelect";
import Select from "../../../../components/Select";
import { Field } from "formik";

export default function AttributeFormGroup({ value, sleutel, attribute, setFieldValue, errors, onChangeCallback = null }) {
    switch (attribute.type) {
        case "multiple_choice":
            const options = attribute.options.map((option) => ({ value: option.id, label: option.title }));

            return (
                <div key={sleutel} className="form-group">
                    <label>{attribute.question}</label>
                    {attribute.description && <p className="description" dangerouslySetInnerHTML={{ __html: attribute.description }} />}
                    <Select
                        name={`attributes[${attribute.id}]`}
                        value={value !== undefined ? options.find((option) => option.label === value) : undefined}
                        options={options}
                        onChange={(option) => {
                            if (attribute.key !== "children_amount" || option.label !== "0") {
                                setFieldValue(`attributes[${attribute.key}]`, option.value);
                            }

                            if (attribute.key === "children_amount") {
                                if (onChangeCallback !== null) onChangeCallback(attribute, option);
                            }
                        }}
                    />
                    {errors?.attributes?.hasOwnProperty(attribute.key) && <div className="error">{errors?.attributes[attribute.key]}</div>}
                </div>
            );
        case "multiple_answer":
            return (
                <div key={sleutel} className="form-group">
                    <label>{attribute.question}</label>
                    {attribute.description && <p className="description" dangerouslySetInnerHTML={{ __html: attribute.description }} />}
                    <MultiSelect
                        name={`attributes[${attribute.id}]`}
                        onChange={(options) => setFieldValue(`attributes[${attribute.key}]`, options.map((option) => option.value).join("|"))}
                        options={attribute.options.map((option) => ({ value: option.id, label: option.title }))}
                    />
                    {errors?.attributes?.hasOwnProperty(attribute.key) && <div className="error">{errors?.attributes[attribute.key]}</div>}
                </div>
            );
        case "multiple_open":
            //FIXME: This could be done better
            const years = Array.isArray(value) ? value : Number.isInteger(parseInt(value)) ? new Array(parseInt(value)).fill("") : [""];

            return (
                <div key={sleutel} className="form-group">
                    <label>{attribute.question}</label>
                    {attribute.description && <p className="description" dangerouslySetInnerHTML={{ __html: attribute.description }} />}
                    <div className="multi_input">
                        {attribute.key === "dob_children" &&
                            years.map((year, i) => (
                                <Field
                                    key={i}
                                    maxLength={4}
                                    name={`attributes[${attribute.key}][${i}]`}
                                    placeholder={"Jaar"}
                                    className="form-control"
                                />
                            ))}
                    </div>
                    {errors?.attributes?.hasOwnProperty(attribute.key) && <div className="error">{errors?.attributes[attribute.key]}</div>}
                </div>
            );
        case "checkbox":
            return (
                <div key={sleutel} className="form-group">
                    <div className="checkbox">
                        <Field
                            id={`attributes[${attribute.key}]`}
                            name={`attributes[${attribute.key}]`}
                            checked={value}
                            type="checkbox"
                            className="form-control"
                        />
                        <label htmlFor={`attributes[${attribute.key}]`}>{attribute.question}</label>
                    </div>
                    {attribute.description && <p className="description pl-cb" dangerouslySetInnerHTML={{ __html: attribute.description }} />}
                    {errors?.attributes?.hasOwnProperty(attribute.key) && <div className="error">{errors?.attributes[attribute.key]}</div>}
                </div>
            );
        default: //open
            return (
                <div key={sleutel} className="form-group">
                    <label>{attribute.question}</label>
                    {attribute.description && <p className="description" dangerouslySetInnerHTML={{ __html: attribute.description }} />}
                    <Field name={`attributes[${attribute.key}]`} maxLength={attribute.key === "zipcode" ? 4 : null} className="form-control" />
                    {errors?.attributes?.hasOwnProperty(attribute.key) && <div className="error">{errors?.attributes[attribute.key]}</div>}
                </div>
            );
    }
}
