import Popup from "reactjs-popup";
import arrow_left_gray from "../../assets/images/icons/arrow_left_gray.svg";
import arrow_right_white from "../../assets/images/icons/arrow_right_white.svg";
import { useMutation } from "react-query";
import { postRequestUnsubscribe } from "../../api/api";
import { Link } from "react-router-dom";

export default function NoChildrenPopup({ open, setOpen, withUnsubscribe = false }) {
    function unsubscribeUser() {
        console.log("UNSUBBING");
    }

    return (
        <Popup open={open} onClose={() => setOpen(false)}>
            <h2 className="title">Helaas kan je niet meedoen</h2>
            <p>
                De onderzoeken in het Landelijk Ouderpanel zijn bedoeld voor ouders met kinderen jonger dan 23 jaar. Helaas kunnen we je daarom niet
                uitnodigen voor onze onderzoeken.
                <br />
                <br />
                Wil je meer informatie over onderwijs? Bekijk dan{" "}
                <a target="_blank" href="https://oudersenonderwijs.nl" rel="noreferrer">
                    de website van Ouders & Onderwijs
                </a>
                .
            </p>
            {withUnsubscribe && (
                <div className="buttons_container mt-2">
                    <button className="btn kingkong btn-back popup-back noty_close_with_click" type="button" onClick={() => setOpen(false)}>
                        <img src={arrow_left_gray} alt="arrow" />
                        <span style={{ paddingLeft: "10px" }} className="popup-span"></span>
                    </button>

                    <Link to={"/uitschrijven"} className="btn kingkong btn-primary">
                        <img src={arrow_right_white} alt="arrow" />
                        <span style={{ paddingLeft: "10px" }}>Uitschrijven</span>
                    </Link>
                    {/* <button className="btn kingkong btn-primary noty_close_with_click" type="button" onClick={() => unsubscribeUser()}>
                        <img src={arrow_right_white} alt="arrow" />
                        <span style={{ paddingLeft: "10px" }}>Uitschrijven</span>
                    </button> */}
                </div>
            )}
        </Popup>
    );
}
